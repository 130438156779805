import reactParse from 'html-react-parser'

export function htmlReactParse(
  input: string,
  preprocessor?: (s: string) => string
): string | JSX.Element | JSX.Element[] {
  const p = preprocessor ?? (s => s)
  try {
    return reactParse(p(input))
  } catch (e) {
    // clear all tags
    return p(input).replace(/<[^>]*>/gs, '')
  }
}
