import { useEffect, useRef } from 'react'

type Input = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
export function useAutoFocus<T extends Input>(focused: boolean): React.RefObject<T> {
  const ref = useRef<T>(null)
  useEffect(() => {
    const { current } = ref
    if (!current || !focused) {
      return
    }
    current.focus()
  }, [focused])

  return ref
}
