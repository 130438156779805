import { FC } from 'react'
import { AdInferePathFunc, useAdPath } from '../hooks/use-ad-path'

interface AdPathProps {
  inferePath: AdInferePathFunc
}

const AdPath: FC<AdPathProps> = ({ inferePath }) => {
  useAdPath({
    inferePath,
  })

  return null
}

export default AdPath
