import { createContainer } from 'shared-components/unstated'
import { ThemeVariants } from 'shared-definitions/types'
import { useEffect, useState } from 'react'

export interface ThemeValueProps {
  theme: ThemeVariants
  ready: boolean
}

export interface ThemeContextProps extends ThemeValueProps {
  setTheme: (newState: ThemeVariants) => void
}

function themeModeMatcher(condition: ThemeVariants): boolean {
  return window.matchMedia(`(prefers-color-scheme: ${condition})`).matches
}

const key = 'site-theme'

function updateKey(value: ThemeVariants): void {
  localStorage.setItem(key, value)
}

function getKey(): string | null {
  return localStorage.getItem(key)
}

export const ThemeContext = createContainer<ThemeContextProps, ThemeValueProps>(
  (initial = { theme: 'light', ready: false }) => {
    const [theme, setTheme] = useState<ThemeVariants>(initial.theme)
    const [ready, setReady] = useState(initial.ready)
    const updateFunc = (newVal: ThemeVariants): void => {
      updateKey(newVal)
      setTheme(newVal)
      setReady(true)
    }

    useEffect(() => {
      const savedValue = getKey()
      if (savedValue) {
        setTheme(savedValue === 'dark' ? 'dark' : 'light')
        setReady(true)
        return
      }

      updateFunc(themeModeMatcher('dark') ? 'dark' : 'light')
    }, [])

    return {
      ready,
      theme,
      setTheme: updateFunc,
    }
  }
)
