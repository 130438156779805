import { useEffect, useState } from 'react'
import { isInIframe } from 'shared-code/util'
import { AffilateContext } from 'shared-components/contexts/AffilateContext'
import { AudioContext } from 'shared-components/contexts/AudioContext'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import { NotificationsContext } from 'shared-components/contexts/NotificationsContext'
import { PageBodyContext } from 'shared-components/contexts/PageBodyContext'
import { PopupContext } from 'shared-components/contexts/PopupContext'
import { PostContext } from 'shared-components/contexts/PostContext'
import { ReadingProgressContext } from 'shared-components/contexts/ReadingProgressContext'
import { ResponsiveContext } from 'shared-components/contexts/ResponsiveContext'
import { ScrollContext } from 'shared-components/contexts/ScrollContext'
import { ScrollUpContext } from 'shared-components/contexts/ScrollUpContext'
import { ThemeContext } from 'shared-components/contexts/ThemeContext'
import { TooltipContext } from 'shared-components/contexts/TooltipContext'
import TooltipLayer from 'shared-components/layout/TooltipLayer'
import PageHead from 'shared-components/service/PageHead'
import { DSFeed, DSPage, DSTooltip } from 'shared-definitions/types'
import { useIsPreview } from '../hooks/use-is-preview'

interface Props extends DSFeed {
  page: DSPage
  tooltips?: Record<string, DSTooltip>
  rss?: boolean
}

interface PageContainerProps {
  props: Props
  layout: React.FC<React.PropsWithChildren>
}

const PageContainer: React.FC<PageContainerProps & React.PropsWithChildren> = ({
  children,
  props,
  layout: Layout,
}) => {
  const isPreview = useIsPreview()
  const [showAdminPanel, setShowAdminPanel] = useState(isPreview)

  useEffect(() => {
    setShowAdminPanel(isPreview)
  }, [isPreview])

  useEffect(() => {
    if (isInIframe()) {
      setShowAdminPanel(false)
    }
  }, [])

  return (
    <ThemeContext.Provider>
      <PopupContext.Provider>
        <ResponsiveContext.Provider>
          <NotificationsContext.Provider>
            <CommonDataContext.Provider
              initialState={{
                showAdminPanel,
                preview: props.preview,
                common: props.common,
                ts: props.ts,
              }}
            >
              <PostContext.Provider
                initialState={{
                  ...props.page.meta,
                  title: props.page.head.metaTitle,
                  pagePath: props.page.pagePath,
                  sections: props.page.sections,
                }}
              >
                <TooltipContext.Provider
                  initialState={{ tooltips: props.tooltips ? props.tooltips : {} }}
                >
                  <PageBodyContext.Provider>
                    <ScrollContext.Provider>
                      <ScrollUpContext.Provider initialState={{ scrollMargin: 200 }}>
                        <ReadingProgressContext.Provider>
                          <AudioContext.Provider>
                            <AffilateContext.Provider>
                              <Layout>
                                <PageHead
                                  ampPageLink={props.page.ampPagePath}
                                  head={props.page.head}
                                  pageLink={props.page.pagePath}
                                  rss={props.rss}
                                />
                                {children}
                              </Layout>
                              <TooltipLayer />
                            </AffilateContext.Provider>
                          </AudioContext.Provider>
                        </ReadingProgressContext.Provider>
                      </ScrollUpContext.Provider>
                    </ScrollContext.Provider>
                  </PageBodyContext.Provider>
                </TooltipContext.Provider>
              </PostContext.Provider>
            </CommonDataContext.Provider>
          </NotificationsContext.Provider>
        </ResponsiveContext.Provider>
      </PopupContext.Provider>
    </ThemeContext.Provider>
  )
}

export default PageContainer
