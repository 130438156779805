import { createContainer } from 'shared-components/unstated'

export interface UIVariantContextProps {
  postPreviewScore: 'ring' | 'line'
  buttonStyle: 'fill' | 'outline'
}

export const UIVariantContext = createContainer<UIVariantContextProps, UIVariantContextProps>(
  initial => {
    if (!initial) {
      throw new Error('Undefined context initial value')
    }
    return {
      ...initial,
    }
  }
)
