import { getds } from 'ds'
import React, { useCallback } from 'react'
import TopSearch from 'shared-components/layout/TopSearch'
import TopSearchControl from 'shared-components/layout/TopSearchControl'
import { BasicStyledComponent } from 'shared-definitions/types'

type TopSearchPlaceholderProps = BasicStyledComponent

const TopSearchPlaceholder: React.VFC<TopSearchPlaceholderProps> = () => {
  const searchHandler = useCallback(async (query: string, perPage: number) => {
    const dataSource = await getds()
    const { data } = await dataSource.getSearchPage(query, 1, perPage)
    return data.posts
  }, [])

  return <TopSearch searchHandler={searchHandler} control={TopSearchControl} />
}

export default TopSearchPlaceholder
