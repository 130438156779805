import Head from 'next/head'
import { useState, useEffect } from 'react'
import { logWarning } from 'shared-code/log'
import { PostContext } from 'shared-components/contexts/PostContext'

interface SailthruTrackerProps {
  customerId: string
}

const SailthruTracker: React.VFC<SailthruTrackerProps> = ({ customerId }) => {
  const [initialized, setInitialized] = useState(false)
  const { tags, title, pagePath } = PostContext.useContainer()

  useEffect(() => {
    const { Sailthru } = window
    if (typeof Sailthru !== 'undefined' && tags.length) {
      const params = {
        url: pagePath,
        tags: tags.map(i => i.label.toLowerCase().replace(/\s+/g, '-')),
      }
      try {
        Sailthru.track('pageview', params)
      } catch (e) {
        logWarning('Sailthru error', e, params)
      }
    }
  }, [tags, pagePath, customerId, initialized])

  useEffect(() => {
    // because of async nature of runtime, delaying initialization
    const sthruIntervar = setInterval(() => {
      const { Sailthru } = window
      if (typeof Sailthru !== 'undefined') {
        clearInterval(sthruIntervar)
        Sailthru.init({
          customerId,
          autoTrackPageview: false,
          useStoredTags: false,
          // isCustom: true,
        })
        setInitialized(true)
      }
    }, 1)
  }, [customerId])

  if (!customerId) {
    return null
  }

  return (
    <Head>
      <meta key="sailthru.title" name="sailthru.title" content={title} />
      {tags.length ? (
        <meta
          key="sailthru.tags"
          name="sailthru.tags"
          content={tags.map(i => i.label.toLocaleLowerCase().replace(/\s+/g, '-')).join(',')}
        />
      ) : null}
      <script
        defer
        key="saithru-tracker-core"
        src="https://ak.sail-horizon.com/spm/spm.v1.min.js"
      />
    </Head>
  )
}

export default SailthruTracker
