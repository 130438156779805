import { createContainer } from 'shared-components/unstated'
import { DSFeed } from 'shared-definitions/types'

export type CommonDataContextProps = DSFeed & { showAdminPanel: boolean }

export const CommonDataContext = createContainer<CommonDataContextProps, CommonDataContextProps>(
  initial => {
    if (!initial) {
      throw new Error('Undefined context initial value')
    }
    return {
      ...initial,
    }
  }
)
