import { createContainer } from 'shared-components/unstated'
import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react'

interface PageBodyValueProps {
  layout: HTMLElement | null
  showReadingProgress: boolean
}

interface PageBodyContextProps extends PageBodyValueProps {
  setState: Dispatch<SetStateAction<PageBodyValueProps>>
}

export const PageBodyContext = createContainer<PageBodyContextProps, PageBodyValueProps>(
  (initial = { layout: null, showReadingProgress: false }) => {
    const [state, setState] = useState(initial)
    return {
      ...state,
      setState,
    }
  }
)

export function usePageBodyLayout<T extends HTMLElement>(): RefObject<T> {
  const { setState } = PageBodyContext.useContainer()
  const ref = useRef<T>(null)
  useEffect(() => {
    if (!ref.current) {
      return
    }
    setState({ showReadingProgress: true, layout: ref.current })
    return () => setState({ showReadingProgress: false, layout: null })
  }, [setState])

  return ref
}
