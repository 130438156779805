import clsx from 'clsx'

import Linked, { LinkedProps } from 'shared-components/service/Linked'
import type { BasicStyledComponent } from 'shared-definitions/types'

import stylesButton from 'shared-components/buttons/CartButtonLink.module.css'
import styleContentButton from 'shared-components/styles/content-button.module.css'

interface OfferButtonLinkProps extends BasicStyledComponent, LinkedProps {
  innerClassName?: string
  onClick?: () => void
}

const OfferButtonLink: React.FC<OfferButtonLinkProps & React.PropsWithChildren> = ({
  className,
  children,
  innerClassName,
  ...props
}) => (
  <Linked className={clsx(styleContentButton.button, stylesButton.container, className)} {...props}>
    <div className={clsx(styleContentButton.inner, innerClassName)}>
      <span className={styleContentButton.innerText}>{children}</span>
    </div>
  </Linked>
)

export default OfferButtonLink
