import { useEffect } from 'react'
import OneSignal from 'react-onesignal'
import { logNotice } from 'shared-code/log'
import { PostContext } from 'shared-components/contexts/PostContext'

interface OneSignalScriptProps {
  appId: string
  loadDelay: number
  tagLimit: number
}

const OneSignalScript: React.VFC<OneSignalScriptProps> = ({ appId, loadDelay, tagLimit }) => {
  const { subscribeTags } = PostContext.useContainer()

  useEffect(() => {
    setTimeout(() => {
      void OneSignal.init({ appId })
    }, loadDelay)
  })

  useEffect(() => {
    void (async () => {
      const sendTagsList: Record<string, string> = {}
      subscribeTags.forEach(tag => {
        const lsKey = 'onesignal:' + tag,
          stored = localStorage.getItem(lsKey)

        let tagVisits = 1
        if (stored) {
          const storedParsed = parseInt(stored, 10)
          if (!isNaN(storedParsed)) {
            tagVisits = storedParsed + 1
          }
        }

        localStorage.setItem(lsKey, tagVisits.toString())
        if (tagVisits >= tagLimit) {
          sendTagsList[tag] = tagVisits.toString()
        }
      })
      if (Object.keys(sendTagsList).length) {
        logNotice('Onesignal tag list', sendTagsList)
        const pushEnabled = await OneSignal.isPushNotificationsEnabled()
        logNotice('Onesignal isPushNotificationsEnabled', pushEnabled)
        if (pushEnabled) {
          void OneSignal.sendTags(sendTagsList)
        }
      }
    })()
  }, [subscribeTags, tagLimit])
  return null
}

export default OneSignalScript
