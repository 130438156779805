import type { InsightsClient } from 'search-insights'
import type {
  InsightsClickObjectIDsEvent,
  InsightsSearchClickEvent,
} from 'search-insights/dist/click'
import type { InsightsSearchConversionObjectIDsEvent } from 'search-insights/dist/conversion'

let _appId: string | null = null
let _apiKey: string | null = null
let _index: string | null = null

let initialized = false

export function init(appId: string, apiKey: string, index: string): void {
  _appId = appId
  _apiKey = apiKey
  _index = index
}

async function getClient(): Promise<InsightsClient | null> {
  if (initialized) {
    const { default: aa } = await import('search-insights')
    return aa
  }
  if (!initialized && _appId && _apiKey) {
    initialized = true

    const { default: aa } = await import('search-insights')
    aa('init', {
      appId: _appId,
      apiKey: _apiKey,
      useCookie: true,
    })

    return aa
  }

  return null
}

export async function clickedObjectIDsAfterSearch(
  data: Omit<InsightsSearchClickEvent, 'userToken' | 'timestamp' | 'index'>
): Promise<void> {
  const client = await getClient()
  if (client && _index) {
    client('clickedObjectIDsAfterSearch', { ...data, index: _index })
  }
}

export async function convertedObjectIDs(
  data: Omit<InsightsSearchConversionObjectIDsEvent, 'userToken' | 'timestamp' | 'index'>
): Promise<void> {
  const client = await getClient()
  if (client && _index) {
    client('convertedObjectIDs', { ...data, index: _index })
  }
}

export async function clickedObjectIDs(
  data: Omit<InsightsClickObjectIDsEvent, 'userToken' | 'timestamp' | 'index'>
): Promise<void> {
  const client = await getClient()
  if (client && _index) {
    client('clickedObjectIDs', { ...data, index: _index })
  }
}
