import { createContainer } from 'shared-components/unstated'
import { useState } from 'react'
import { useWindowScrollState } from '../hooks/use-window-scroll-state'

interface ScrollContextProps {
  scrollHeight: number
  scrollY: number
}

export const ScrollContext = createContainer<ScrollContextProps, null>(() => {
  const [state, setState] = useState({
    scrollHeight: 0,
    scrollY: 0,
  })

  useWindowScrollState(() => {
    setState({
      scrollHeight: document.body.scrollHeight,
      scrollY: window.scrollY,
    })
  })

  return state
})
