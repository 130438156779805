export function arrayChunks<T>(array: readonly T[], chunkSize: number): T[][] {
  let i, j
  const result = []
  for (i = 0, j = array.length; i < j; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}

export function range(num: number): number[] {
  const result = []
  for (let i = 0; i < num; i++) {
    result.push(i)
  }
  return result
}

// margin should describe place for other array to be inserted (must be between 0 and 1)
export function mixArrays<T>(
  sourceArr: readonly T[],
  other: { arr: readonly T[]; margin: number }[],
  checker: (previous: T, next: T) => boolean
): T[] {
  let result: T[] = []
  let left = 0
  for (const { arr, margin } of other.sort((a, b) => a.margin - b.margin)) {
    let sepIndex = Math.max(Math.floor(sourceArr.length * margin), 1) // can't insert in the beginning
    while (sourceArr[sepIndex] && !checker(sourceArr[sepIndex - 1], sourceArr[sepIndex])) {
      sepIndex++
    }
    result = result.concat(sourceArr.slice(left, sepIndex), arr)
    left = sepIndex
  }
  return result.concat(sourceArr.slice(left))
}
