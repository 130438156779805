import React, { useEffect } from 'react'

interface BodyScriptProps {
  src: string
  async?: boolean
}

const BodyScript: React.VFC<BodyScriptProps> = ({ src, async }) => {
  useEffect(() => {
    const scr = document.createElement('script')
    scr.src = src
    scr.async = async === true

    document.body.appendChild(scr)

    return () => {
      scr.remove()
    }
  }, [src, async])

  return null
}

export default BodyScript
