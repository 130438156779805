/* eslint-disable react/jsx-no-bind */
import stylesUtils from 'shared-components/styles/util.module.css'
import { PageBodyContext } from 'shared-components/contexts/PageBodyContext'
import styles from './UnderNavBlock.module.css'
import ReadingProgressBar from 'shared-components/ui/ReadingProgressBar'
import DealBar from './DealBar'
import clsx from 'clsx'
import { ScrollUpContext } from 'shared-components/contexts/ScrollUpContext'
import BestBuyPanel from 'shared-components/layout/BestBuyPanel'
import { AffilateContext } from 'shared-components/contexts/AffilateContext'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { DealBarContext } from 'shared-components/contexts/DealBarContext'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import { observeScroll } from 'shared-code/observer'
import useDebouncedState from 'shared-components/hooks/use-debounced-state'

const TableOfContents = dynamic(() => import('shared-components/ui/TableOfContents'), {
  ssr: false,
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UnderNavBlockProps {}

/*
- dealbar
- affilate bar
- mobile table of contents
*/
const UnderNavBlock: React.VFC<UnderNavBlockProps> = () => {
  const { showAdminPanel } = CommonDataContext.useContainer()
  const { scrolledUp } = ScrollUpContext.useContainer()
  const { showReadingProgress } = PageBodyContext.useContainer()
  const { bars, activeIndex, visible } = AffilateContext.useContainer()
  const bestBuyItem = bars[activeIndex] ?? null
  const [focus, setFocus] = useState(false)
  const { dealbar, hiddenOnMobile: dealbarHiddenOnMobile } = DealBarContext.useContainer()

  const hasAffilate = bestBuyItem && visible
  const dealbarSticky = dealbar?.sticky === true

  // toc
  const [showToc, setShowToc] = useDebouncedState(false, 300)
  useEffect(() => {
    const header = document.querySelector('header') // hack
    if (!header) {
      // eslint-disable-next-line no-console
      console.warn('UnderNavBlock depends on header DOM element')
      return
    }

    const observerX2 = observeScroll(header, window.innerHeight, setShowToc)
    return () => {
      observerX2.disconnect()
    }
    // eslint rule disabled intentionally
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isHiddenTableOfContents =
    scrolledUp || (dealbarSticky && !dealbarHiddenOnMobile && !hasAffilate) || !showToc

  return (
    <>
      {showReadingProgress ? (
        <ReadingProgressBar
          className={clsx(styles.progress, {
            [styles.scrolledUp]: scrolledUp,
            [styles.previewMode]: showAdminPanel,
          })}
        />
      ) : null}
      <DealBar
        className={clsx(styles.dealbar, {
          [styles.hasReadingProgress]: showReadingProgress,
          [styles.scrolledUp]: scrolledUp,
          [styles.hidden]: hasAffilate,
          [styles.previewMode]: showAdminPanel,
        })}
      />
      <div
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        className={clsx(styles.underDealBar, {
          [styles.focused]: focus,
          [styles.previewMode]: showAdminPanel,
          [styles.hasReadingProgress]: showReadingProgress,
          [styles.hidden]: !hasAffilate && isHiddenTableOfContents,
        })}
      >
        <div className={clsx(stylesUtils.wrapper, styles.inner)}>
          {bestBuyItem ? (
            <BestBuyPanel
              product={bestBuyItem}
              className={clsx(styles.panel, { [styles.hidden]: !hasAffilate })}
            />
          ) : null}
          <TableOfContents
            variant="top"
            className={clsx(styles.table, {
              [styles.hidden]: isHiddenTableOfContents,
            })}
          />
        </div>
      </div>
    </>
  )
}

export default UnderNavBlock
