import Head from 'next/head'
import { useEffect } from 'react'
import { logWarning } from 'shared-code/log'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'

function isRampRuntime(item: unknown): item is PlaywireRampRuntimeProps {
  if (typeof item !== 'object' || !item) {
    logWarning('Ramp object is not defined')
    return false
  }

  const result = 'destroyUnits' in item && 'addUnits' in item && 'displayUnits' in item

  if (!result) {
    logWarning('Ramp functions are undefined')
  }
  return result
}

interface PlaywireRampScriptProps {
  publisherId: string
  websiteId: string
}

const PlaywireRampScript: React.VFC<PlaywireRampScriptProps> = ({ publisherId, websiteId }) => {
  const { setInst } = PlaywireContext.useContainer()

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.rampIsInitialized === true) {
        clearInterval(interval)
        setInst(isRampRuntime(window.ramp) ? window.ramp : null)
      }
    }, 1)
    return () => clearInterval(interval)
  }, [setInst])

  return (
    <Head>
      <script
        key="playwire-code"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            var ramp = { 
              passiveMode: true,
              que: [],
            }; 
            var rampCb = function() {
              window.rampIsInitialized = true;
              window.PageOS.setClientTag(6, 'tevax');
            }
            window.ramp.que.push(rampCb)

            document.body.addEventListener('click', function(e) {
              if (e.target.hasAttribute("data-button-load-more")) {
                window.PageOS.setClientTag(6, 'tevax');
              }
            })

            
            var eid = window.localStorage.getItem('ramp_raw_eid') ?? undefined
            if (eid) {
              ramp.up = {
                format:'EUP1',
                eid:eid
              }
            }
      `,
        }}
      />
      <script
        key="playwire-runtime"
        type="text/javascript"
        async
        src={`//cdn.intergient.com/${publisherId}/${websiteId}/ramp.js`}
      />
    </Head>
  )
}

export default PlaywireRampScript
