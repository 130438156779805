import { createContainer } from 'shared-components/unstated'
import { useRef, useState } from 'react'
import { useWindowScrollState } from '../hooks/use-window-scroll-state'

interface ScrollUpValueProps {
  scrollMargin: number
}

interface ScrollUpContextProps {
  scrolledUp: boolean
}

export const ScrollUpContext = createContainer<ScrollUpContextProps, ScrollUpValueProps>(
  initial => {
    if (!initial) {
      throw new Error('ScrollUp context: ScrollUp margin should be set')
    }

    const [scrolledUp, setScrolledUp] = useState(false)
    const lastScrollUp = useRef(0)
    const scrollUpDiff = useRef(0)

    useWindowScrollState(() => {
      const scrollY = window.scrollY

      if (scrollY < window.innerHeight) {
        // disable scroll up behavior on first screen
        setScrolledUp(false)
        return
      }

      scrollUpDiff.current += lastScrollUp.current - scrollY
      scrollUpDiff.current = Math.max(scrollUpDiff.current, 0)

      const scrolledUpFlag = lastScrollUp.current > scrollY
      lastScrollUp.current = scrollY

      if (!scrolledUpFlag) {
        scrollUpDiff.current = 0
      }

      setScrolledUp(scrollUpDiff.current > initial.scrollMargin)
    })

    return {
      scrolledUp,
    }
  }
)
