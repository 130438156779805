import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import Day from 'shared-svg/Day.svg'
import Night from 'shared-svg/Night.svg'
import { ThemeContext } from 'shared-components/contexts/ThemeContext'
import styles from './ThemeSwitcher.module.css'

interface ThemeSwitcherProps extends BasicStyledComponent {
  size: 'md' | 'lg'
}

const ThemeSwitcher: React.VFC<ThemeSwitcherProps> = ({ className, size }) => {
  const { theme, setTheme } = ThemeContext.useContainer()
  return (
    <button
      type="button"
      aria-label="Switch theme"
      className={clsx(className, styles.button, {
        [styles.buttonMd]: size === 'md',
        [styles.buttonLg]: size === 'lg',
        [styles.buttonDark]: theme === 'dark',
        [styles.buttonLight]: theme === 'light',
      })}
      onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
    >
      <Day
        className={clsx(styles.icon, {
          [styles.iconMd]: size === 'md',
          [styles.iconLg]: size === 'lg',
          [styles.lightIconLight]: theme === 'light',
          [styles.lightIconDark]: theme === 'dark',
        })}
      />
      <Night
        className={clsx(styles.icon, {
          [styles.iconMd]: size === 'md',
          [styles.iconLg]: size === 'lg',
          [styles.darkIconDark]: theme === 'dark',
          [styles.darkIconLight]: theme === 'light',
        })}
      />
    </button>
  )
}

export default ThemeSwitcher
