import { Dispatch, SetStateAction, useRef, useState } from 'react'

export default function useDebouncedState<T>(
  initial: T,
  delay: number
): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(initial)
  const timeout = useRef<NodeJS.Timeout>()

  return [
    state,
    (value: SetStateAction<T>) => {
      if (timeout.current !== undefined) {
        clearTimeout(timeout.current)
      }

      timeout.current = setTimeout(() => {
        setState(value)
        timeout.current = undefined
      }, delay)
    },
  ]
}
