/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import { CSSProperties, useEffect, useState } from 'react'
import { htmlReactParse } from 'shared-code/html'
import { TooltipContext } from 'shared-components/contexts/TooltipContext'
import styles from './TooltipLayer.module.css'

const TooltipLayer: React.VFC = () => {
  const { tooltip, active, setActive, pos } = TooltipContext.useContainer()
  const [currentTooltip, setCurrentTooltip] = useState(() => tooltip)
  const [hasHover, setHasHover] = useState(false)

  useEffect(() => {
    setHasHover(window.matchMedia('(hover: hover)').matches)
  }, [])

  useEffect(() => {
    if (tooltip) {
      setCurrentTooltip(tooltip)
    }
  }, [tooltip])

  return (
    <div
      className={clsx(styles.container, { [styles.active]: active && tooltip !== null })}
      onClick={e => {
        if (e.target === e.currentTarget) {
          setActive(null)
        }
      }}
    >
      <div
        className={styles.hoverContainer}
        onMouseLeave={() => {
          if (hasHover) {
            setActive(null)
          }
        }}
        style={
          pos
            ? ({
                '--left': `${pos.left}px`,
                '--top': `${pos.top}px`,
              } as CSSProperties)
            : undefined
        }
      >
        <div className={styles.tooltip}>
          <div className={styles.head}>{currentTooltip?.title}</div>
          <div className={styles.text}>
            {currentTooltip ? htmlReactParse(currentTooltip.html) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TooltipLayer
