import { createContainer } from 'shared-components/unstated'
import { NotificationProps } from 'shared-definitions/types'
import { useRef, useState } from 'react'

export interface NotificationsValueProps {
  queue: readonly NotificationProps[]
}

type EnqueMsg = Pick<NotificationProps, 'msg' | 'variant' | 'duration'>
interface NotificationsContextProps extends NotificationsValueProps {
  enqueue: (msg: EnqueMsg) => void
  clear: () => void
  remove: (id: NotificationProps['id']) => void
}

export const NotificationsContext = createContainer<
  NotificationsContextProps,
  NotificationsValueProps
>((initial = { queue: [] }) => {
  const [queue, setQueue] = useState(initial.queue)
  const cleanupTimeout = useRef<NodeJS.Timeout>()
  const durationTimeouts = useRef(new Map<number, NodeJS.Timeout | undefined>())

  function scheduleCleanup(): void {
    if (cleanupTimeout.current) {
      clearTimeout(cleanupTimeout.current)
    }
    cleanupTimeout.current = setTimeout(() => {
      setQueue(s => {
        for (const item of s) {
          if (item.visible) {
            return s
          }
        }

        for (const timer of durationTimeouts.current.values()) {
          if (timer) {
            clearTimeout(timer)
          }
        }
        durationTimeouts.current = new Map()

        return []
      })
      cleanupTimeout.current = undefined
    }, 1000)
  }

  function remove(id: number): void {
    setQueue(s => {
      if (s[id]) {
        s[id].visible = false
      }
      return [...s]
    })
    scheduleCleanup()
  }

  function enqueue(msg: EnqueMsg): void {
    setQueue(s => {
      const duration = msg.duration ?? Infinity
      const id = s.length

      if (duration !== Infinity) {
        durationTimeouts.current.set(
          id,
          setTimeout(() => {
            remove(id)
            durationTimeouts.current.set(id, undefined)
          }, duration)
        )
      }

      return [
        ...s,
        {
          ...msg,
          id,
          duration,
          visible: true,
        },
      ]
    })
  }

  function clear(): void {
    setQueue([])
  }

  return {
    queue,
    clear,
    enqueue,
    remove,
  }
})
