import clsx from 'clsx'
import React from 'react'
import { priceFormat, wrapWithCurrency } from 'shared-code/format'
import OfferButtonLink from 'shared-components/buttons/OfferButtonLink'
import Score from 'shared-components/ui/Score'
import { BasicStyledComponent, ProductBarProps } from 'shared-definitions/types'
import Cart from 'shared-svg/Cart.svg'
import styles from './BestBuyPanel.module.css'

interface BestBuyPanelProps extends BasicStyledComponent {
  product: ProductBarProps
}

const STATIC_CLASS_NAME = 'affiliate-bar'

const BestBuyPanel: React.VFC<BestBuyPanelProps> = ({ className, product }) => (
  <div className={clsx(STATIC_CLASS_NAME, styles.container, className)}>
    <div className={styles.name}>
      <div className={styles.nameWrapper}>
        <span className={styles.nameText}>{product.name}</span>
        {product.msrp ? (
          <div className={styles.info}>
            {'MSRP: '}
            {wrapWithCurrency(priceFormat(product.msrp.price), product.msrp.currency)}
          </div>
        ) : null}
      </div>
      {product.score ? <Score score={product.score} className={styles.scoreLine} /> : null}
    </div>
    <OfferButtonLink
      data-sel="bestbuy-panel-link"
      {...product.button.pLink}
      innerClassName={styles.buttonInner}
      className={styles.button}
    >
      <span className={styles.buttonText}>{product.button.label}</span>
      <Cart className={styles.buttonIcon} />
    </OfferButtonLink>
  </div>
)

export default BestBuyPanel
