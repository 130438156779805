import { useCallback } from 'react'
import { LinkedLinkProps } from 'shared-definitions/types'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'

export type UseSearchLinkBuilderProps = (q: string) => LinkedLinkProps

export function useSearchLinkBuilder(): UseSearchLinkBuilderProps {
  const { common } = CommonDataContext.useContainer()
  return useCallback(
    (q: string): LinkedLinkProps => ({ href: `${common.searchLink.href}?q=${encodeURI(q)}` }),
    [common.searchLink]
  )
}
