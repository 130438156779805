import { createContainer } from 'shared-components/unstated'
import { useScrollProgress } from 'shared-components/hooks/use-scroll-progress'
import { PageBodyContext } from 'shared-components/contexts/PageBodyContext'

interface ReadingProgressValueProps {
  progress: number
}

type ReadingProgressContextProps = ReadingProgressValueProps

export const ReadingProgressContext = createContainer<
  ReadingProgressContextProps,
  ReadingProgressValueProps
>(() => {
  const { layout: el } = PageBodyContext.useContainer()
  const progress = useScrollProgress({ el })

  return {
    progress,
  }
})
