import Head from 'next/head'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'

interface AdBlockerScript {
  blockScript: string
}

const AdBlockerScript: React.VFC<AdBlockerScript> = ({ blockScript }) => {
  const { inst } = PlaywireContext.useContainer()

  if (!inst) {
    return null
  }

  return (
    <Head>
      <script
        defer
        key="blocker-script"
        id="blocker-script"
        src={blockScript}
        type="text/javascript"
      />
    </Head>
  )
}

export default AdBlockerScript
