import { useState, useEffect } from 'react'
import { ScrollContext } from 'shared-components/contexts/ScrollContext'

interface UseScrollProgressOpts {
  el: HTMLElement | null
}

export function useScrollProgress({ el }: UseScrollProgressOpts): number {
  const [progress, setProgress] = useState(0)
  const { scrollHeight, scrollY } = ScrollContext.useContainer()

  useEffect(() => {
    if (!el) {
      setProgress(0)
      return
    }

    const endLine =
      (el ? el.offsetTop + el.getBoundingClientRect().height : scrollHeight) - window.innerHeight
    const newProgress = scrollY / endLine
    setProgress(Math.min(1, newProgress))
  }, [el, scrollHeight, scrollY])

  return progress
}
