import { Dispatch, SetStateAction, useState } from 'react'
import { createContainer } from 'shared-components/unstated'

export interface AudioValueProps {
  playingUrl: null | string
}

export interface AudioContextProps extends AudioValueProps {
  setPlayingUrl: Dispatch<SetStateAction<string | null>>
}

export const AudioContext = createContainer<AudioContextProps, AudioValueProps>(
  (initial = { playingUrl: null }) => {
    const [playingUrl, setPlayingUrl] = useState(initial.playingUrl)
    return {
      playingUrl,
      setPlayingUrl,
    }
  }
)
