/* eslint-disable react/display-name */
import clsx from 'clsx'
import Head from 'next/head'
import { BasicStyledComponent, BlockSizeConstraints, DSImageProps } from 'shared-definitions/types'
import styles from './AppImage.module.css'
import { remConvert } from 'shared-code/conversion'
import { extractSizes, BreakPointSize } from 'shared-code/responsive'
import { CSSProperties, forwardRef } from 'react'

interface AppImagePropsCommon extends BasicStyledComponent {
  image: DSImageProps
  sizes: BreakPointSize[] | BreakPointSize
  preload?: boolean
  eager?: boolean
  imageClassName?: string
}

interface AppResponsiveImageProps extends AppImagePropsCommon {
  ratio?: BlockSizeConstraints
  ratioVars?: boolean
  layout: 'responsive'
}

interface AppRawImageProps extends AppImagePropsCommon {
  layout: 'raw'
}

interface AppFillImageProps extends AppImagePropsCommon {
  layout: 'fill'
}

type AppImageProps = AppResponsiveImageProps | AppRawImageProps | AppFillImageProps

const AppImage = forwardRef<HTMLPictureElement, AppImageProps>((props, ref) => {
  const inferredSizes = remConvert(extractSizes(props.sizes))
  let pictureStyles: CSSProperties | undefined

  if (props.layout === 'responsive') {
    const r = props.ratio ?? props.image.size ?? { w: 16, h: 9 }
    const inferredPadding = (r.h / r.w) * 100
    pictureStyles = {
      paddingTop: inferredPadding.toFixed(2) + '%',
      aspectRatio: `${r.w} / ${r.h}`,
    }
    if (props.ratioVars) {
      const ratioVars = {
        '--ratio-w': r.w,
        '--ratio-h': r.h,
      } as CSSProperties
      pictureStyles = { ...pictureStyles, ...ratioVars }
    }
  }

  return (
    <>
      <picture
        ref={ref}
        className={clsx(props.className, styles.container, {
          [styles.fill]: props.layout === 'fill',
        })}
        style={pictureStyles}
      >
        {props.image.webpSrcSet ? (
          <source sizes={inferredSizes} srcSet={props.image.webpSrcSet} type="image/webp" />
        ) : null}
        <img
          className={clsx(props.imageClassName, styles.image)}
          decoding="async"
          loading={props.preload || props.eager ? 'eager' : 'lazy'}
          sizes={inferredSizes}
          title={props.image.title ?? undefined}
          srcSet={props.image.srcSet ?? undefined}
          alt={props.image.alt ?? undefined}
          src={props.image.src}
        />
      </picture>
      {props.preload ? (
        <Head>
          <link
            key={props.image.src + inferredSizes}
            rel="preload"
            as="image"
            href={props.image.src}
            imageSrcSet={props.image.webpSrcSet ?? props.image.srcSet ?? undefined}
            // eslint-disable-next-line react/no-unknown-property
            imagesizes={inferredSizes}
          />
        </Head>
      ) : null}
    </>
  )
})

export default AppImage
