import { DSPostContext } from 'shared-definitions/types'
import { createContainer } from 'shared-components/unstated'

type PostContextProps = DSPostContext

export const PostContext = createContainer<PostContextProps, PostContextProps>(initial => {
  if (!initial) {
    throw new Error('Undefined context initial value')
  }
  return {
    ...initial,
  }
})
