import { useState } from 'react'
import { createContainer } from 'shared-components/unstated'
import { DSTooltip } from 'shared-definitions/types'
import { useGlobalKey } from 'shared-components/hooks/use-global-key'

interface Position {
  left: number
  top: number
}

export interface TooltipValueProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  tooltips: Record<string, DSTooltip>
}

interface TooltipContextProps extends TooltipValueProps {
  setActive: (id: string | null) => void
  setPos: (pos: Position | null) => void
  active: string | null
  pos: Position | null
  tooltip: null | DSTooltip
}

export const TooltipContext = createContainer<TooltipContextProps, TooltipValueProps>(initial => {
  if (!initial) {
    throw new Error('Undefined context initial value')
  }
  const [active, setActive] = useState<string | null>(null)
  const [pos, setPos] = useState<Position | null>(null)

  useGlobalKey({ code: 'Escape' }, () => setActive(null))

  return {
    ...initial,
    setActive,
    active,
    pos,
    setPos,
    tooltip: active && initial.tooltips[active] ? initial.tooltips[active] : null,
  }
})
