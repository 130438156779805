import clsx from 'clsx'
import type { BasicStyledComponent } from 'shared-definitions/types'
import styles from './ReadingProgressBar.module.css'
import { ReadingProgressContext } from 'shared-components/contexts/ReadingProgressContext'

type ReadingProgressBarProps = BasicStyledComponent

const ReadingProgressBar: React.VFC<ReadingProgressBarProps> = ({ className }) => {
  const { progress } = ReadingProgressContext.useContainer()

  return (
    <div className={clsx(styles.container, className)}>
      <div
        className={styles.shuttle}
        style={{ transform: `translateX(-${(1 - progress) * 100}%)` }}
      />
    </div>
  )
}

export default ReadingProgressBar
