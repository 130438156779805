import { createContainer } from 'shared-components/unstated'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { ProductBarProps } from 'shared-definitions/types'
import { useWindowScrollState } from '../hooks/use-window-scroll-state'

interface AffilateValueProps {
  bars: ProductBarProps[]
  activeIndex: number
  visible: boolean
}

interface AffilateContextProps extends AffilateValueProps {
  setState: Dispatch<SetStateAction<AffilateValueProps>>
}

export const AffilateContext = createContainer<AffilateContextProps, AffilateValueProps>(
  (initial = { bars: [], activeIndex: 0, visible: false }) => {
    const [state, setState] = useState(initial)
    const lastScroll = useRef(0)
    const scrollUpDiff = useRef(0)

    useWindowScrollState(() => {
      const scrolledTop = window.scrollY > window.innerHeight
      if (!scrolledTop) {
        if (state.visible) {
          setState(s => ({ ...s, visible: false }))
        }
        return
      }

      scrollUpDiff.current += lastScroll.current - window.scrollY
      scrollUpDiff.current = Math.max(scrollUpDiff.current, 0)

      const scrolledUp = lastScroll.current > window.scrollY
      lastScroll.current = window.scrollY

      if (!scrolledUp) {
        scrollUpDiff.current = 0
      }

      const scrollUpMargin = 100
      const wantedVisible = scrollUpDiff.current < scrollUpMargin
      if (state.visible !== wantedVisible) {
        setState(s => ({ ...s, visible: wantedVisible }))
      }
    })

    return {
      ...state,
      setState,
    }
  }
)
