import { createContainer } from 'shared-components/unstated'
import React, { useCallback, useState } from 'react'
import { useGlobalKey } from 'shared-components/hooks/use-global-key'

export interface PopupValueProps {
  popupContent: React.ReactElement | null
  popupOpened: boolean
  popupClassName: string | null
}

export interface PopupContextProps extends PopupValueProps {
  setPopupContent: (newContent: React.ReactElement | null, className?: string | null) => void
  setPopupOpened: (newState: boolean) => void
}

export const PopupContext = createContainer<PopupContextProps, PopupValueProps>(
  (initial = { popupContent: null, popupOpened: false, popupClassName: null }) => {
    const [popupContent, setPopupContent] = useState(initial.popupContent)
    const [popupOpened, setPopupOpened] = useState(initial.popupOpened)
    const [popupClassName, setPopupClassName] = useState(initial.popupClassName)

    useGlobalKey({ code: 'Escape' }, () => setPopupOpened(false))

    return {
      popupContent,
      popupOpened,
      popupClassName,
      setPopupOpened,
      setPopupContent: useCallback((newContent, newClassName) => {
        setPopupContent(newContent)
        setPopupClassName(newClassName ?? null)
      }, []),
    }
  }
)
