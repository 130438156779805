import styles from './Score.module.css'
import Star from 'shared-svg/Star.svg'
import { range } from 'shared-code/array'
import { BasicStyledComponent } from 'shared-definitions/types'
import clsx from 'clsx'
import { pxToRem } from 'shared-code/conversion'

interface ScoreProps extends BasicStyledComponent {
  score: number
}

// constants
const starsCount = 5
const maxScoreVal = 10

// css values
const starSize = 12
const starGap = 5

const Score: React.VFC<ScoreProps> = ({ score, className }) => {
  const inferedScore = score / (maxScoreVal / starsCount)

  const stars = range(starsCount).map(index => <Star className={styles.icon} key={index} />)

  const overlayStyle = {
    width: `${pxToRem(inferedScore * starSize + Math.floor(inferedScore) * starGap)}rem`,
  }

  return (
    <div className={clsx(styles.container, className)}>
      {stars}
      <div style={overlayStyle} className={styles.overlay}>
        {stars}
      </div>
    </div>
  )
}

export default Score
