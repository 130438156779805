import { useState } from 'react'
import { createContainer } from 'shared-components/unstated'

export interface PlaywireValueProps {
  inst: PlaywireRuntimeProps | null
  enabled: boolean
  adClassList: readonly string[]
}

export interface PlaywireContextProps extends PlaywireValueProps {
  setInst: (inst: PlaywireRuntimeProps | null) => void
}

export const PlaywireContext = createContainer<PlaywireContextProps, PlaywireValueProps>(
  initial => {
    if (!initial) {
      throw new Error('Undefined playwire initial props')
    }

    const [inst, setInst] = useState<PlaywireRuntimeProps | null>(initial.inst)

    return {
      adClassList: initial.adClassList,
      enabled: initial.enabled,
      inst,
      setInst,
    }
  }
)
