import Router from 'next/router'
import { useEffect, useState } from 'react'

export function useIsPreview(): boolean {
  const [isPreview, setIsPreview] = useState(false)
  useEffect(() => {
    setIsPreview(Router.isPreview)
    console.log('Router.isPreview', Router.isPreview)
  }, [])
  return isPreview
}
