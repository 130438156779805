const LdJsonList: React.VFC<{ records: readonly any[] }> = ({ records }) => (
  <>
    {records.map((value, index) => (
      <script
        key={`ld-json-${index}`}
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(value),
        }}
      />
    ))}
  </>
)

export default LdJsonList
