import { BasicStyledComponent } from 'shared-definitions/types'
import styleUtils from 'shared-components/styles/util.module.css'
import clsx from 'clsx'
import { useOutboundLinkListener } from '../hooks/use-outbound-link-listener'

const Main: React.FC<React.PropsWithChildren & BasicStyledComponent> = ({
  className,
  children,
}) => {
  const outboundLinkListener = useOutboundLinkListener()

  return (
    <main className={clsx(className, styleUtils.main)} ref={outboundLinkListener}>
      {children}
    </main>
  )
}

export default Main
