import Head from 'next/head'

interface OptimizeScriptProps {
  optimizeId: string
}

const OptimizeScript: React.VFC<OptimizeScriptProps> = ({ optimizeId }) => (
  <Head>
    <script
      key="optimize-runtime"
      defer
      src={`https://www.googleoptimize.com/optimize.js?id=${optimizeId}`}
    />
  </Head>
)

export default OptimizeScript
