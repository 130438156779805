import { useRef } from 'react'
import { useWindowEventListener } from './use-window-event-listener'

export function useWindowScrollState(handlerWorker: () => void): void {
  const animation = useRef<number>()

  const handler = (): void => {
    if (animation.current) {
      cancelAnimationFrame(animation.current)
    }
    animation.current = requestAnimationFrame(handlerWorker)
  }

  useWindowEventListener('scroll', handler)
  useWindowEventListener('resize', handler)
}
