/* eslint-disable react/display-name */
import dynamic, { DynamicOptionsLoadingProps } from 'next/dynamic'
import React, { Ref } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './DesktopNavPlaceholder.module.css'

const TopNavLoading = React.forwardRef<HTMLDivElement, DynamicOptionsLoadingProps>(
  (_props, ref) => <div ref={ref} className={styles.desktopNav} />
) as (
  loadingProps: DynamicOptionsLoadingProps & { ref?: Ref<HTMLDivElement> }
) => React.ReactElement | null

const DesktopNav = dynamic(() => import('shared-components/layout/DesktopNav'), {
  loading: TopNavLoading,
  ssr: false,
})

const TopNavPlaceholder: React.VFC = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  if (inView) {
    return <DesktopNav className={styles.desktopNav} />
  }

  return <TopNavLoading ref={ref} />
}

export default TopNavPlaceholder
