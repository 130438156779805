import { useRef, useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react'
import { DSSearchResponse } from 'shared-definitions/types'
import { useDebounce } from 'shared-components/hooks/use-debaunce'

interface UseSearchOpts {
  pending: boolean
  results: DSSearchResponse | null
  setValue: Dispatch<SetStateAction<string>>
  value: string
  clearResults: () => void
  currentQuery: string
}

export type SearchHandler = (query: string) => Promise<DSSearchResponse | null>

export function useSearch(searchHandler: SearchHandler): UseSearchOpts {
  const search = useRef<SearchHandler>()
  const [pending, setPending] = useState(false)
  const [results, setResults] = useState<DSSearchResponse | null>(null)
  const [value, setValue] = useState('')

  useEffect(() => {
    search.current = searchHandler
  }, [searchHandler])

  const currentQuery = useDebounce(value, 300)
  useEffect(() => {
    if (currentQuery) {
      void (async () => {
        const { current } = search
        if (current) {
          setPending(true)
          setResults(await current(currentQuery))
          setPending(false)
        }
      })()
    }
  }, [currentQuery])

  return {
    currentQuery,
    pending,
    results,
    setValue,
    value,
    clearResults: useCallback(() => {
      setResults(prev => {
        if (prev && prev.items.length) {
          return null
        }
        return prev
      })
      setValue('')
    }, []),
  }
}
