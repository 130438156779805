import { useEffect, useRef } from 'react'

export function useWindowEventListener<K extends keyof WindowEventMap>(
  eventNames: K | K[],
  handler: (ev: WindowEventMap[K]) => any
): void {
  const savedHandler = useRef(handler)

  // Update the handler ref if the handler function changes
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    // Convert eventNames to an array if it's not already
    const eventNamesArray = Array.isArray(eventNames) ? eventNames : [eventNames]

    // Create event listener that calls handler function stored in ref
    const eventListener = (event: WindowEventMap[K]): void => {
      savedHandler.current(event)
    }

    // Add event listeners for each event name
    eventNamesArray.forEach(eventName => {
      window.addEventListener(eventName, eventListener, { passive: true })
    })

    // Remove event listeners on cleanup
    return () => {
      eventNamesArray.forEach(eventName => {
        window.removeEventListener(eventName, eventListener)
      })
    }
  }, [eventNames]) // Re-run if eventNames or handler changes
}
