import styles from './PageTransition.module.css'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import clsx from 'clsx'

const PageTransition: React.VFC = () => {
  const [transition, setTranstition] = useState(false)
  const router = useRouter()

  useEffect(() => {
    const complete = (): void => {
      setTranstition(false)
    }
    const start = (): void => {
      setTranstition(true)
    }
    router.events.on('routeChangeComplete', complete)
    router.events.on('routeChangeStart', start)
    return () => {
      router.events.off('routeChangeComplete', complete)
      router.events.off('routeChangeStart', start)
    }
  }, [router.events])

  return <div className={clsx(styles.container, { [styles.transition]: transition })} />
}

export default PageTransition
