import clsx from 'clsx'
import { BasicStyledComponent, DSSearchResponse } from 'shared-definitions/types'
import styles from './TopSearch.module.css'
import { useCallback, useState } from 'react'
import { useGlobalKey } from 'shared-components/hooks/use-global-key'
import { useSearch } from 'shared-components/hooks/use-search'
import InstantSearchResult from 'shared-components/layout/InstantSearchResult'
import { ResponsiveContext } from 'shared-components/contexts/ResponsiveContext'
import { useSearchLinkBuilder } from 'shared-components/hooks/use-search-link-builder'
import { CTopSearchControlProps } from 'shared-definitions/types.component'

type SearchHandler = (query: string, perPage: number) => Promise<DSSearchResponse | null>

interface TopSearchProps extends BasicStyledComponent {
  searchHandler: SearchHandler
  control: React.ComponentType<CTopSearchControlProps>
}

const TopSearch: React.VFC<TopSearchProps> = ({ className, searchHandler, control: Control }) => {
  const [focused, setFocused] = useState(false)

  const { ready, xl } = ResponsiveContext.useContainer()
  const { value, setValue, results, pending, clearResults, currentQuery } = useSearch(query => {
    const perPage = ready && xl ? 9 : 12
    return searchHandler(query, perPage)
  })

  const closeCallback = useCallback(() => {
    setFocused(false)
    clearResults()
  }, [clearResults])

  useGlobalKey({ code: 'Escape' }, closeCallback)

  const resultsLinkBuilder = useSearchLinkBuilder()
  const props = {
    focused,
    currentQuery,
    value,
    pending,
    setValue,
    setFocused,
    closeCallback,
  }

  return (
    <>
      <Control {...props} className={className} />
      <InstantSearchResult
        className={clsx(styles.results, {
          [styles.resultsHidden]: !results || !results.items.length || pending || !focused,
        })}
        resultsLink={resultsLinkBuilder(currentQuery)}
        posts={results}
        onNavigate={closeCallback}
        query={currentQuery}
      />
    </>
  )
}

export default TopSearch
