import type { VFC } from 'react'

interface AlternateLinkProps {
  path: string
}

const AlternateLink: VFC<AlternateLinkProps> = ({ path }) => (
  <link rel="alternate" type="application/rss+xml" href={`${path}feed`} />
)

export default AlternateLink
