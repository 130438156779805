import clsx from 'clsx'
import { useRouter } from 'next/router'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import { useAutoFocus } from 'shared-components/hooks/use-auto-focus'
import { useSearchLinkBuilder } from 'shared-components/hooks/use-search-link-builder'
import { CTopSearchControlProps } from 'shared-definitions/types.component'
import Close from 'shared-svg/Close.svg'
import Magnifier from 'shared-svg/Magnifier.svg'
import stylesCommon from './TopSearchControl.TopSearchPlaceholder.module.css'
import styles from './TopSearchControl.module.css'

const TopSearchControl: React.VFC<CTopSearchControlProps> = ({
  className,
  focused,
  currentQuery,
  value,
  pending,
  setValue,
  setFocused,
  closeCallback,
}) => {
  const router = useRouter()
  const resultsLinkBuilder = useSearchLinkBuilder()

  const { common } = CommonDataContext.useContainer()

  // fix for mobile version due lazy TopSearch loading (TopSearchPlaceholderLazy)
  // useEffect(() => {
  //   setFocused('ontouchstart' in document.documentElement)
  // }, [])

  return (
    <div className={clsx(styles.container, className)}>
      <button
        type="button"
        aria-label="Search"
        className={clsx(stylesCommon.button, styles.buttonSearch, {
          [styles.buttonFocused]: focused,
        })}
        onClick={() => {
          if (focused) {
            if (currentQuery) {
              const link = resultsLinkBuilder(currentQuery).href
              void router.push(link)
            }
            setFocused(false)
          } else {
            setFocused(true)
          }
        }}
      >
        <Magnifier className={stylesCommon.icon} />
      </button>
      <div
        className={clsx(styles.form, {
          [styles.formFocused]: focused,
          [styles.formPending]: pending,
        })}
      >
        <input
          ref={useAutoFocus(focused)}
          type="text"
          onChange={e => setValue(e.target.value)}
          value={value}
          placeholder={common.strings.searchPlaceholder}
          className={styles.input}
        />
        <button
          onClick={closeCallback}
          type="button"
          className={clsx(stylesCommon.button, styles.buttonFocused, styles.buttonClose)}
        >
          <Close className={stylesCommon.icon} />
        </button>
        <div className={styles.pendingBar} />
      </div>
    </div>
  )
}

export default TopSearchControl
