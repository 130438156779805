/* eslint-disable promise/prefer-await-to-callbacks */
import { useWindowEventListener } from 'shared-components/hooks/use-window-event-listener'

interface Key {
  code: string
  alt?: boolean
  ctrl?: boolean
}

export function useGlobalKey(key: Key, cb: () => void): void {
  useWindowEventListener('keydown', (e: KeyboardEvent): void => {
    if (key.alt && !e.altKey) {
      return
    }

    if (key.ctrl && !e.ctrlKey) {
      return
    }

    if (e.code === key.code) {
      cb()
    }
  })
}
