/* eslint-disable react/jsx-no-bind */
import { BasicStyledComponent, DSSearchResponse, LinkedLinkProps } from 'shared-definitions/types'
import clsx from 'clsx'
import styles from './InstantSearchResult.module.css'
import styleUtils from 'shared-components/styles/util.module.css'
import ArrowRight from 'shared-svg/ArrowRight.svg'
import Linked from 'shared-components/service/Linked'
import AppImage from 'shared-components/ui/AppImage'
import { PostContext } from '../contexts/PostContext'
import { AppEventsContext } from '../contexts/AppEventsContext'

interface InstantSearchResultProps extends BasicStyledComponent {
  posts: DSSearchResponse | null
  query: string
  className?: string
  resultsLink: LinkedLinkProps
  onNavigate: () => void
}

const InstantSearchResult: React.VFC<InstantSearchResultProps> = ({
  query,
  resultsLink,
  posts,
  className,
  onNavigate,
}) => {
  const { appEvent } = AppEventsContext.useContainer()
  const postMeta = PostContext.useContainer()
  return (
    <div className={clsx(styles.container, className)}>
      <div className={clsx(styleUtils.wrapper, styles.results)}>
        <div className={clsx(styles.resultsContainer, styleUtils.thinScrollbar)}>
          <h3 className={styles.resultsHeading}>
            {'Search results for'}
            <span className={styles.resultsQuery}>{query}</span>
          </h3>
          {posts &&
            posts.items.map((item, index) => (
              <Linked
                className={styles.resultsLink}
                {...item.pLink}
                key={index}
                onClick={() => {
                  onNavigate()

                  if (!posts) {
                    return
                  }
                  appEvent({
                    event: 'search-result-clicked',
                    desc: 'Clicked on header results',
                    queryId: posts.queryId,
                    objectId: item.objectId,
                    position: index + 1,
                    postMeta,
                  })
                }}
              >
                {item.image ? (
                  <AppImage
                    key={`img_${index}`}
                    layout="responsive"
                    className={styles.resultsLinkImage}
                    image={item.image}
                    sizes="rem(80)"
                  />
                ) : (
                  <div key={`div_${index}`} />
                )}
                <span className={styles.resultsSpan} key={`span_${index}`}>
                  {item.title}
                </span>
              </Linked>
            ))}
        </div>
      </div>
      <Linked className={styles.allResultsLink} {...resultsLink} onClick={onNavigate}>
        <span className={clsx(styleUtils.wrapper, styles.allResultsInner)}>
          {'All search results'} <ArrowRight className={styles.allResultsLinkIcon} />
        </span>
      </Linked>
    </div>
  )
}

export default InstantSearchResult
