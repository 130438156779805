import { createContainer } from 'shared-components/unstated'
import { responsiveMap, responsiveMatcher } from 'shared-code/responsive'
import { useEffect, useState } from 'react'
import { useWindowEventListener } from 'shared-components/hooks/use-window-event-listener'

export interface ResponsiveValueProps extends ReturnType<typeof responsiveMap> {
  ready: boolean
}

export type ResponsiveContextProps = ResponsiveValueProps

// TODO: ref to matchMedia
export const ResponsiveContext = createContainer<ResponsiveContextProps, ResponsiveValueProps>(
  (initial = { ready: false, ...responsiveMap(() => false) }) => {
    const [val, setVal] = useState(initial)

    const handler = (): void => {
      const newState = { ready: true, ...responsiveMap(responsiveMatcher) }
      setVal(prev => {
        for (const key in newState) {
          if (
            newState[key as keyof ResponsiveValueProps] !== prev[key as keyof ResponsiveValueProps]
          ) {
            return newState
          }
        }
        return prev
      })
    }
    useWindowEventListener('resize', handler)
    useEffect(handler, [])

    return { ...val }
  }
)
