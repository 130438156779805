import { createContainer } from 'shared-components/unstated'
import { AppEvent } from 'shared-code/app-events'
import { MapAppEventListeners } from 'shared-code/app-events-listeners'

interface AppEventsValueProps {
  listeners: MapAppEventListeners<AppEvent, 'event', void>
}

interface AppEventsContextProps {
  appEvent: (e: AppEvent) => void
}

export const AppEventsContext = createContainer<AppEventsContextProps, AppEventsValueProps>(
  initial => {
    if (!initial?.listeners) {
      throw new Error('AppEventsContext: listeners are required')
    }

    return {
      appEvent: (e: AppEvent): void => {
        initial.listeners[e.event].forEach(h => h(e as never)) // TODO: fix "never" hack
      },
    }
  }
)
