import clsx from 'clsx'
import { PopupContext } from 'shared-components/contexts/PopupContext'
import styles from './PopupWrapper.module.css'
import { ThemeContext } from 'shared-components/contexts/ThemeContext'
import { useEffect } from 'react'

const PopupWrapper: React.VFC = () => {
  const { popupOpened, popupClassName, popupContent, setPopupOpened } = PopupContext.useContainer()
  const { theme } = ThemeContext.useContainer()

  useEffect(() => {
    document.documentElement.classList[popupOpened ? 'add' : 'remove'](styles.html)
  }, [popupOpened])

  return (
    <div
      data-popup
      aria-hidden
      role="button"
      onClick={(e: { target: EventTarget }) => {
        const target = e.target as HTMLElement
        if (target.dataset['popup']) {
          setPopupOpened(false)
        }
      }}
      className={clsx(styles.wrapper, popupClassName, {
        [styles.wrapperClosed]: !popupOpened,
        [styles.wrapperDark]: theme === 'dark',
        [styles.wrapperLight]: theme === 'light',
      })}
    >
      {popupContent}
    </div>
  )
}

export default PopupWrapper
