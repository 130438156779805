import { useRouter } from 'next/router'
import { useEffect } from 'react'
import Script from 'next/script'

interface FunnelScriptProps {
  customerId: string
}

const FunnelScript: React.VFC<FunnelScriptProps> = ({ customerId }) => {
  const router = useRouter()
  // const firstRun = useRef(true)

  useEffect(() => {
    const completeHandler = (remainingDelay: number): void => {
      const { trx } = window
      if (trx) {
        trx.magicLinksEngine = new trx.MagicLinks(false)
        trx.magicLinksEngine.run(true)
        return
      }

      setTimeout(() => {
        if (remainingDelay > 0) {
          completeHandler(remainingDelay - 100)
        }
      }, 100)
    }

    const complete = (): void => {
      // console.log('FUNNEL COMPLETE', firstRun.current)
      completeHandler(3000)
      // if (firstRun.current) {
      //   firstRun.current = false
      // } else {
      //   completeHandler(3000)
      // }
    }

    router.events.on('routeChangeComplete', complete)
    return () => {
      router.events.off('routeChangeComplete', complete)
    }
  }, [customerId, router.events])

  return (
    <>
      <Script
        key="funnel-relay-installer"
        id="funnel-relay-installer"
        data-autorun="true"
        data-property-id="PROPERTY_ID"
        data-customer-id={customerId}
        src={`https://cdn-magiclinks.trackonomics.net/client/static/v2/${customerId}.js`}
        strategy="afterInteractive"
      />
      <Script
        id="funnel-relay"
        key="funnel-relay"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            (function(window, document, account) {
            if (window.trxact) {return}
            window.trxact = true;
            window.skimlinks_exclude = ["shop-links.co", "shop-edits.co"];
            window.NRTV_EVENT_DATA = { donotlink: true };
            var b = document.createElement("script");
            b.type = "text/javascript";
            b.src = "https://static.narrativ.com/tags/" + account + ".js";
            b.async = true;
            var a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(b,a);
            })(window, document, "androidauthority");
        `,
        }}
        strategy="afterInteractive"
      />
    </>
  )
}

export default FunnelScript
