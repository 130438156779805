/* eslint-disable promise/prefer-await-to-callbacks */
export function observeScroll<T extends HTMLElement>(
  el: T,
  scroll: number,
  cb: (s: boolean) => void
): IntersectionObserver {
  const o = new IntersectionObserver(
    ([e]) => {
      if (e) {
        cb(e.isIntersecting)
      }
    },
    {
      root: document.body,
      rootMargin: `-${scroll}px 0px 0px 0px`,
      threshold: 0,
    }
  )

  o.observe(el)

  return o
}
