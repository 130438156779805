import Head from 'next/head'
import React from 'react'
import BodyScript from 'shared-components/service/BodyScript'

interface AffilimateScriptProps {
  token: string
}

const AffilimateScript: React.VFC<AffilimateScriptProps> = ({ token }) => (
  <>
    <Head>
      <React.Fragment key="affilimate-script">
        <meta name="am-api-token" content={token} />
      </React.Fragment>
    </Head>
    <BodyScript async src="https://snippet.affilimate.io" />
  </>
)

export default AffilimateScript
