/* eslint-disable react/jsx-no-bind */
import dynamic from 'next/dynamic'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import { PopupContext } from 'shared-components/contexts/PopupContext'
import Burger from 'shared-svg/Burger.svg'
import styles from './MobileNavTrigger.module.css'
import Close from 'shared-svg/Close.svg'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'

const MobileNav = dynamic(() => import('shared-components/layout/MobileNav'), { ssr: false })

const MobileNavTrigger: React.VFC<BasicStyledComponent> = ({ className }) => {
  const { popupOpened, setPopupOpened, setPopupContent } = PopupContext.useContainer()
  const { common, showAdminPanel } = CommonDataContext.useContainer()

  return (
    <button
      aria-label="Show menu"
      type="button"
      className={clsx(styles.mobileNavButton, className)}
      onClick={() => {
        if (popupOpened) {
          setPopupOpened(false)
          return
        }

        setPopupContent(
          <MobileNav common={common} onNavigate={() => setPopupOpened(false)} />,
          clsx(styles.popupClass, { [styles.popupPreview]: showAdminPanel })
        )
        setPopupOpened(true)
      }}
    >
      {popupOpened ? (
        <Close className={styles.mobileNavIcon} />
      ) : (
        <Burger className={styles.mobileNavIcon} />
      )}
    </button>
  )
}

export default MobileNavTrigger
