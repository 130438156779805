import { getds } from 'ds'
import { useEffect } from 'react'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'

export function useViewCounter(postId: number | string | null): void {
  const { ts } = CommonDataContext.useContainer()

  useEffect(() => {
    if (!postId) {
      return
    }

    void (async () => {
      const ds = await getds({ ts })
      await ds.incrementViewCount({ postId: postId.toString() })
    })()
  }, [postId, ts])
}
