export function nullableNumber(input: undefined | number | string | null): number | null {
  if (input === 0 || input === '' || input === '0' || input === null || input === undefined) {
    return null
  }
  if (typeof input === 'string') {
    return parseFloat(input)
  }
  return input
}

export function nullableString(input: unknown): string | null {
  if (typeof input !== 'string' || input === '') {
    return null
  }
  return input
}

// export function objectToFormData(o: Record<string, unknown>): FormData {
//   const body = new FormData()
//   for (const key in o) {
//     if (!Object.prototype.hasOwnProperty.call(o, key)) {
//       continue
//     }
//     if (['string', 'number'].includes(typeof o[key])) {
//       body.append(key, (o[key] as string).toString())
//     }
//     if (typeof o[key] === 'object') {
//       // FileList
//       const list = o[key] as FileList
//       for (const file of list) {
//         body.append(key, file)
//       }
//     }
//   }
//   return body
// }

export function remConvert(input: string): string {
  return input.replace(/rem\(([-\d]+)\)/g, (_m, d) => {
    if (typeof d === 'string') {
      return pxToRem(parseInt(d, 10)).toString() + 'rem'
    }
    throw new Error('Unexpected incoming value: ' + JSON.stringify(d))
  })
}

export function browserRemToPx(rem: number): number {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export function pxToRem(input: number): number {
  return input * 0.0625
}

export function extractColorDigits(
  input: string,
  fallback: [number, number, number] = [255, 255, 255]
): [number, number, number] {
  if (/^\s*#[\dabcdef]{6}\s*$/i.test(input)) {
    const matches = /^\s*#(.{2})(.{2})(.{2})\s*$/.exec(input)
    if (matches) {
      return [parseInt(matches[1], 16), parseInt(matches[2], 16), parseInt(matches[3], 16)]
    }
  }
  if (/^\s*#[\dabcdef]{3}\s*$/i.test(input)) {
    const matches = /^\s*#(.{1})(.{1})(.{1})\s*$/.exec(input)
    if (matches) {
      return [
        parseInt(matches[1].repeat(2), 16),
        parseInt(matches[2].repeat(2), 16),
        parseInt(matches[3].repeat(2), 16),
      ]
    }
  }

  return fallback // fallback color
}
