import Head from 'next/head'
import { useEffect } from 'react'
import { gtmDatalayer } from 'shared-code/gtm'
import { PostContext } from 'shared-components/contexts/PostContext'

interface GTMProps {
  gtmId: string
}

let lastItems: string[] = [] // can't use  useRef here, because component unmounts on every page transition
const GTM: React.VFC<GTMProps> = ({ gtmId }) => {
  const { dataLayer } = PostContext.useContainer()

  useEffect(() => {
    const message = dataLayer ?? {}

    const rObject = lastItems.reduce((c, i) => {
      c[i] = undefined
      return c
    }, {} as Record<string, undefined>)

    void gtmDatalayer({ event: 'page-view', ...rObject, ...message })
    lastItems = Object.keys(message)
  }, [dataLayer, gtmId])

  return (
    <Head>
      <script
        key="gtm-script"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmId}');`,
        }}
      />
      <link
        key="analycs-preload"
        rel="preload"
        href="https://www.google-analytics.com/analytics.js"
        as="script"
      />
    </Head>
  )
}

export default GTM
