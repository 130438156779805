import { DSMeta } from 'shared-definitions/types'

interface MetaRecordsProps {
  records: readonly DSMeta[]
}

const MetaRecords: React.VFC<MetaRecordsProps> = ({ records }) => (
  <>
    {records.map((value, index) => (
      <meta key={index} {...value} />
    ))}
  </>
)

export default MetaRecords
